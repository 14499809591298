import { createSelector } from 'reselect'
import { hasSessionPermission } from '@tabeeb/modules/permissions/selectors'
import { getIsCallStarted } from '@tabeeb/modules/presentation/selectors'
import {
  isPdfPage,
  isImagePage,
  isSupportAnnotations,
  isSupportAnnotationsDisplay,
  isSupportRotation,
  isSupportSnippingTool,
} from '@tabeeb/services/pageService'
import { getPagesList as getTowerSideProfilePagesList } from '@tabeeb/modules/towerSideProfile/selectors'
import { getPagesList as get2dLineDrawingPagesList } from '@tabeeb/modules/2dLineDrawing/selectors'
import { getPagesList as getClassificationAnnotationsPagesList } from '@tabeeb/modules/classificationAnnotationsPagesTimeline/selectors'
import { getContentSharingPages, getHasShareContentPermission } from '@tabeeb/modules/contentSharings/selectors'
import { getIsCurrentUserReviewer } from '@tabeeb/modules/../users/selectors'
import { ContentType, ContentReviewStatus, SessionPermission, PointCloudModelStatus } from '@tabeeb/enums'
import { getIsCurrentUserPresenter } from '@tabeeb/modules/shared/content/selectors'
import { getCurrentUserReview, getReviewersUploadedPagesIds } from '@tabeeb/modules/contentReviews/selectors'
import { getCurrentUserId } from '@tabeeb/modules/account/selectors'
import * as splatSelectors from '@tabeeb/modules/splatModel/selectors'
import { addMenuItems } from '../services/galleryMenus'

export const getGalleryList = (state) => state.gallery.galleryList
export const getFoldersList = (state) => state.gallery.foldersList

export const getSelectedGalleryItemId = (state) => state.gallery.galleryState.selectedGalleryItemId
export const getSelectedFolderId = (state) => state.gallery.galleryState.selectedFolderId

export const getCustomVideoTimeLine = (state) => state.customVideo.timelineList
export const getSpatialModelImagesList = (state) => state.spatialModel.imagesList
export const get3dModelImagesList = (state) => state.threedeeModel.imagesList
export const getBentleyModelImagesList = (state) => state.bentleyModel.imagesList
export const getBentleyAnalyticsReportModelsList = (state) => state.bentleyAnalyticsReport.modelsList
export const getPagesForSharingList = (state) => state.shareSessionPagesList.pagesList
export const getStructureModelImagesList = (state) => state.structureModel.imagesList
export const getPagesForReviewingList = (state) => state.reviewSessionPagesList.pagesList

export const getPresenterId = (state) => state.contentState.presenterId
export const getOwnerId = (state) => state.contentState.ownerId
export const getPointCloudModelStatus = (state) => state.contentState.pointCloudModelStatus

export const getSelection = (state) => state.gallery.selection
export const getContentReviews = (state) => state.contentReviews.reviewsList

export const getPdfSearchText = (state) => state.gallery.pdfSearch.searchText
export const getPdfSearchResult = (state) => state.gallery.pdfSearch.selectedPdfSearchResult

export const getAppConfigState = (state) => state.appConfigState

export const getEditingFolder = (state) => state.gallery.editingFolder
export const getContentUsersWithFolderPermissions = (state) => state.gallery.contentUsersWithFolderPermissions
export const getIsPermissionsRequestLoading = (state) => state.gallery.isPermissionsRequestLoading

export const getIsExportImagesRecipientsDialogOpen = (state) => state.gallery.emailRecipientsDialogState === 'image'
export const getIsExportPdfRecipientsDialogOpen = (state) => state.gallery.emailRecipientsDialogState === 'pdf'

export const getHasDeleteRenameAllGalleryContentPermission = (state) =>
  hasSessionPermission(state, SessionPermission.DeleteRenameAllGalleryContent)
export const getHasAddGalleryContentPermission = (state) =>
  hasSessionPermission(state, SessionPermission.AddGalleryContent)
export const getHasDeleteRenameOwnGalleryContentPermission = (state) =>
  hasSessionPermission(state, SessionPermission.DeleteRenameOwnGalleryContent)

export const getAllNestedFoldersIdsForFolderId = (state, { id: folderId, foldersList }) => {
  let list = foldersList
  if (!list) {
    list = getFoldersList(state)
  }

  const folderIds = [folderId]
  for (let i = 0; i < folderIds.length; i++) {
    const currentFolderId = folderIds[i]

    const nestedFoldersIds = list.filter((item) => item.ParentId === currentFolderId).map((item) => item.Id)

    folderIds.push(...nestedFoldersIds)
  }

  return folderIds
}

export const getFolderById = createSelector([getFoldersList, (_, { id }) => id], (foldersList, folderId) => {
  const folder = foldersList.find((f) => f.Id === folderId)
  return folder
})

export const getFoldersByIds = createSelector([getFoldersList, (_, { ids }) => ids], (foldersList, folderIds) => {
  const folderPages = foldersList.filter((folder) => folderIds.has(folder.Id))
  return folderPages
})

export const getGalleryItemById = createSelector([getGalleryList, (_, { id }) => id], (galleryList, pageId) => {
  const page = galleryList.find((p) => p.id === pageId)
  return page
})

export const getGalleryItemsByIds = createSelector([getGalleryList, (_, { ids }) => ids], (galleryList, pageIds) => {
  const pages = galleryList.filter((p) => pageIds.some((pageId) => p.id === pageId))
  return pages
})

export const getSelectedGalleryItem = createSelector(
  [getGalleryList, getSelectedGalleryItemId],
  (galleryList, pageId) => {
    const page = galleryList.find((p) => p.id === pageId)
    return page
  }
)

export const getSelectedGalleryItemType = createSelector(
  [getGalleryList, getSelectedGalleryItemId],
  (galleryList, pageId) => {
    const page = galleryList.find((p) => p.id === pageId)
    return page ? page.contentType : undefined
  }
)

export const getSelectedGalleryItemSupportsAnnotation = createSelector([getSelectedGalleryItemType], (contentType) => {
  return isSupportAnnotations(contentType)
})

export const getSelectedGalleryItemSupportsAnnotationsDisplay = createSelector(
  [getSelectedGalleryItemType],
  (contentType) => {
    return isSupportAnnotationsDisplay(contentType)
  }
)

export const getSelectedGalleryItemSupportsRotation = createSelector([getSelectedGalleryItemType], (contentType) => {
  return isSupportRotation(contentType)
})

export const getSelectedGalleryItemSupportsSnippingTool = createSelector(
  [getSelectedGalleryItemType],
  (contentType) => {
    return isSupportSnippingTool(contentType)
  }
)

export const getSelectedFolder = createSelector(
  [getFoldersList, getSelectedFolderId],
  (foldersList, selectedFolderId) => {
    const selectedFolder = foldersList.find((folder) => folder.Id === selectedFolderId)
    return selectedFolder
  }
)

export const getPagesByFolder = createSelector([getGalleryList, getSelectedFolderId], (galleryList, folderId) => {
  const folderPages = galleryList.filter((page) => page.folderId === folderId)
  return folderPages
})

export const getPagesByFolderWithNesting = createSelector(
  [getGalleryList, getAllNestedFoldersIdsForFolderId],
  (galleryList, folderIds) => {
    const folderPages = galleryList.filter((page) => folderIds.some((id) => page.folderId === id))
    return folderPages
  }
)

export const getNestedFoldersForSelectedFolder = createSelector(
  [getFoldersList, getSelectedFolderId],
  (foldersList, folderId) => {
    const folderFolders = foldersList.filter((folder) => folder.ParentId === folderId)
    return folderFolders
  }
)

export const getInsertionIndex = createSelector(
  [getGalleryList, getReviewersUploadedPagesIds],
  (galleryList, reviewersPagesIds) => {
    let order = galleryList.length > 0 ? Math.max(...galleryList.map((p) => p.order)) : 0
    order += reviewersPagesIds.length
    const insertionIndex = order + 1

    return insertionIndex
  }
)

export const getIsFolderAddedToCustomVideo = createSelector(
  [getGalleryList, getCustomVideoTimeLine, (_, { id }) => id],
  (galleryList, customVideoTimeline, folderId) => {
    const folderPages = galleryList.filter((page) => page.folderId === folderId)

    const isCustomVideoAdded =
      folderPages.length > 0 && folderPages.every((item) => customVideoTimeline.some((image) => image.id === item.id))

    return isCustomVideoAdded
  }
)

export const getIsFolderAddedToSpatialModel = createSelector(
  [getGalleryList, getSpatialModelImagesList, (_, { id }) => id],
  (galleryList, imagesListTimeline, folderId) => {
    const folderPages = galleryList.filter((page) => page.folderId === folderId)

    const pagesForSpatialView = folderPages.filter((image) => isImagePage(image.contentType))

    const isSpatialModelAdded =
      pagesForSpatialView.length > 0 &&
      pagesForSpatialView.every((item) => imagesListTimeline.some((image) => image.id === item.id))

    return isSpatialModelAdded
  }
)

export const getIsFolderAddedTo3dModel = createSelector(
  [getGalleryList, get3dModelImagesList, (_, { id }) => id],
  (galleryList, images, folderId) => {
    const folderPages = galleryList.filter((page) => page.folderId === folderId)

    const isCustomVideoAdded =
      folderPages.length > 0 && folderPages.every((item) => images.some((image) => image.id === item.id))

    return isCustomVideoAdded
  }
)

export const getIsFolderAddedToBentleyModel = createSelector(
  [getGalleryList, getBentleyModelImagesList, (_, { id }) => id],
  (galleryList, images, folderId) => {
    const folderPages = galleryList.filter((page) => page.folderId === folderId)

    const isCustomVideoAdded =
      folderPages.length > 0 && folderPages.every((item) => images.some((image) => image.id === item.id))

    return isCustomVideoAdded
  }
)

export const getIsFolderAddedForSharing = createSelector(
  [getGalleryList, getPagesForSharingList, (_, { id }) => id],
  (galleryList, pagesList, folderId) => {
    const folderPages = galleryList.filter((page) => page.folderId === folderId)

    const isSessionSharingPagesListAdded =
      folderPages.length > 0 && folderPages.every((item) => pagesList.some((page) => page.id === item.id))

    return isSessionSharingPagesListAdded
  }
)

export const getIsFolderAddedForReviewing = createSelector(
  [getGalleryList, getPagesForReviewingList, (_, { id }) => id],
  (galleryList, pagesList, folderId) => {
    const folderPages = galleryList.filter((page) => page.folderId === folderId)

    const isSessionReviewingPagesListAdded =
      folderPages.length > 0 && folderPages.every((item) => pagesList.some((page) => page.id === item.id))

    return isSessionReviewingPagesListAdded
  }
)

export const getIsFolderAddedToStructureModel = createSelector(
  [getGalleryList, getStructureModelImagesList, (_, { id }) => id],
  (galleryList, images, folderId) => {
    const folderPages = galleryList.filter((page) => page.folderId === folderId)

    const isStructureModelImagesListAdded =
      folderPages.length > 0 && folderPages.every((item) => images.some((image) => image.id === item.id))

    return isStructureModelImagesListAdded
  }
)

export const getIsFolderAddedForMerging = createSelector(
  [getGalleryList, getContentSharingPages, (_, { folderId }) => folderId],
  (galleryList, pagesList, folderId) => {
    const folderPages = galleryList.filter((page) => page.folderId === folderId)

    const isFolderPagesListAdded =
      folderPages.length > 0 && folderPages.every((item) => pagesList.some((page) => page.id === item.id))

    return isFolderPagesListAdded
  }
)

export const getIsFolderAddedToClassificationAnnotations = createSelector(
  [getGalleryList, getClassificationAnnotationsPagesList, (_, { id }) => id],
  (galleryList, pagesList, folderId) => {
    const folderPages = galleryList.filter((page) => page.folderId === folderId)

    const isFolderPagesListAdded =
      folderPages.length > 0 && folderPages.every((item) => pagesList.some((page) => page.id === item.id))

    return isFolderPagesListAdded
  }
)

export const getIsFolderAddedToSplatModel = createSelector(
  [getGalleryList, splatSelectors.getPagesList, (_, { id }) => id],
  (galleryList, images, folderId) => {
    const folderPages = galleryList.filter((page) => page.folderId === folderId)

    const isImagesListAdded =
      folderPages.length > 0 && folderPages.every((item) => images.some((image) => image.id === item.id))

    return isImagesListAdded
  }
)

export const getIsItemAddedToSplatModel = createSelector(
  [splatSelectors.getPagesList, getGalleryItemById],
  (splatModelPages, galleryItem) => {
    return splatModelPages.some((item) => item.id === galleryItem.id)
  }
)

export const getCanUserChangeFolder = createSelector(
  [
    getGalleryList,
    getFoldersList,
    getAllNestedFoldersIdsForFolderId,
    getCurrentUserId,
    getHasDeleteRenameAllGalleryContentPermission,
    getHasDeleteRenameOwnGalleryContentPermission,
    getIsCurrentUserPresenter,
    getIsCallStarted,
  ],
  (
    galleryList,
    foldersList,
    folderIds,
    currentUserId,
    hasDeleteRenameAllPermission,
    hasDeleteRenameOwnPermission,
    isPresenter,
    isCallStarted
  ) => {
    const folderWithNestedFolders = foldersList.filter((folder) => folderIds.some((id) => id === folder.Id))
    const isFolderOwner = folderWithNestedFolders.every((folder) => Boolean(currentUserId === folder.UserId))

    const folderPages = galleryList.filter((page) => folderIds.some((id) => id === page.folderId))
    const isAllFolderPagesCanBeDeleted = folderPages
      .filter((page) => !page.pdfPageNumber || page.pdfPageNumber === 1)
      .every((page) => page.canDelete)

    const canChange =
      hasDeleteRenameAllPermission ||
      (isFolderOwner && hasDeleteRenameOwnPermission && isAllFolderPagesCanBeDeleted && (!isCallStarted || isPresenter))

    return canChange
  }
)

export const getCanDeleteGalleryFolder = createSelector(
  [
    getGalleryList,
    getCanUserChangeFolder,
    getContentReviews,
    getIsCurrentUserReviewer,
    getCurrentUserReview,
    (_, { id }) => id,
  ],
  (galleryList, canChange, reviewsList, isReviewer, review, folderId) => {
    if (!canChange) return false

    let canDelete = false

    if (isReviewer) canDelete = Boolean(review && review.ReviewStatus === ContentReviewStatus.Active)
    else {
      const folderPages = galleryList.filter((page) => page.folderId === folderId)

      // Folder can't be deleted if at least one folder page is added for review
      const activeReviews = reviewsList.filter((r) => r.ReviewStatus !== ContentReviewStatus.FeedbackSent)
      canDelete = !activeReviews.some((item) =>
        item.PagesIds.some((pageId) => folderPages.some((fp) => fp.id === pageId))
      )
    }

    return canDelete
  }
)

export const getCanRenameGalleryFolder = createSelector(
  [getCanUserChangeFolder, getIsCurrentUserReviewer, getCurrentUserReview],
  (canChange, isReviewer, review) => {
    if (!canChange) return false

    let canRename = true
    if (isReviewer) canRename = Boolean(review && review.ReviewStatus === ContentReviewStatus.Active)

    return canRename
  }
)

export const getGalleryNotSelectedItemsCount = createSelector(
  [getGalleryList, getSelection],
  (galleryList, selection) => {
    const itemsCount = galleryList
      .filter((page) => !selection.some((id) => id === page.id))
      .filter((page) => !page.pdfPageNumber || page.pdfPageNumber === 1).length

    return itemsCount
  }
)

export const getDifferentPagesCount = createSelector(
  [getGalleryList, getSelectedFolderId],
  (galleryList, selectedFolderId) => {
    let countDifferentPages = 0
    let previousPageContentUrl = ''
    const foldersPages = galleryList.filter((item) => item.folderId === selectedFolderId)
    foldersPages.forEach(function (page) {
      if (!page.contentUrl || previousPageContentUrl !== page.contentUrl) {
        countDifferentPages++
      }
      previousPageContentUrl = page.contentUrl
    })

    return countDifferentPages
  }
)

export const getSelectedFolderGalleryListLength = createSelector([getPagesByFolder], (folderPages) => {
  return folderPages.length
})

export const getIsItemAddedToSpatialModel = createSelector(
  [getSpatialModelImagesList, getGalleryItemById],
  (spatialModelImagesList, galleryItem) => {
    return spatialModelImagesList.some((item) => item.id === galleryItem.id)
  }
)

export const getIsItemAddedToCustomVideo = createSelector(
  [getCustomVideoTimeLine, getGalleryItemById],
  (spatialModelImagesList, galleryItem) => {
    return spatialModelImagesList.some((item) => item.id === galleryItem.id)
  }
)

export const getIsItemAddedTo3dModel = createSelector(
  [get3dModelImagesList, getGalleryItemById],
  (spatialModelImagesList, galleryItem) => {
    return spatialModelImagesList.some((item) => item.id === galleryItem.id)
  }
)

export const getIsItemAddedToBentleyModel = createSelector(
  [getBentleyModelImagesList, getGalleryItemById],
  (bentleyModelImagesList, galleryItem) => {
    return bentleyModelImagesList.some((item) => item.id === galleryItem.id)
  }
)

export const getIsItemAddedToBentleyAnalyticsReport = createSelector(
  [getBentleyAnalyticsReportModelsList, getGalleryItemById],
  (bentleyAnalyticsReportModelsList, galleryItem) => {
    return bentleyAnalyticsReportModelsList.some((item) => item.id === galleryItem.id)
  }
)

export const getIsItemAddedToSelection = createSelector(
  [getSelection, getGalleryItemById],
  (selection, galleryItem) => {
    return selection.some((id) => id === galleryItem.id)
  }
)

export const getIsItemAddedToSharingSessionsPagesList = createSelector(
  [getPagesForSharingList, getGalleryItemById],
  (sharingList, galleryItem) => {
    return sharingList.some((item) => item.id === galleryItem.id)
  }
)

export const getIsItemAddedToReviewingSessionsPagesList = createSelector(
  [getPagesForReviewingList, getGalleryItemById],
  (sharingList, galleryItem) => {
    return sharingList.some((item) => item.id === galleryItem.id)
  }
)

export const getIsItemAddedToStructureModel = createSelector(
  [getStructureModelImagesList, getGalleryItemById],
  (structureImagesList, galleryItem) => {
    return structureImagesList.some((item) => item.id === galleryItem.id)
  }
)

export const getIsItemAddedToActiveReview = createSelector(
  [getContentReviews, getGalleryItemById],
  (reviewsList, galleryItem) => {
    const activeReviews = reviewsList.filter((r) => r.ReviewStatus !== ContentReviewStatus.FeedbackSent)
    return activeReviews.some((item) => item.PagesIds.some((pageId) => pageId === galleryItem.id))
  }
)

export const getIsItemAddedToTowerSideProfile = createSelector(
  [getTowerSideProfilePagesList, getGalleryItemById],
  (towerSideProfilePagesList, galleryItem) => {
    return towerSideProfilePagesList.some((item) => item.id === galleryItem.id)
  }
)

export const getIsItemAddedTo2dLineDrawing = createSelector(
  [get2dLineDrawingPagesList, getGalleryItemById],
  (twoDLineDrawingPagesList, galleryItem) => {
    return twoDLineDrawingPagesList.some((item) => item.id === galleryItem.id)
  }
)

export const getIsAddedToClassificationAnnotations = createSelector(
  [getClassificationAnnotationsPagesList, getGalleryItemById],
  (classificationAnnotationsPagesList, galleryItem) => {
    return classificationAnnotationsPagesList.some((item) => item.id === galleryItem.id)
  }
)

export const getIsItemAddedToContentsMerging = createSelector(
  [getContentSharingPages, getGalleryItemById],
  (sharingPages, galleryItem) => {
    return sharingPages.some((item) => item.id === galleryItem.id)
  }
)

export const getIsFolderCanBeDeleted = createSelector(
  [getGalleryList, getContentReviews, getIsCurrentUserReviewer, getCurrentUserReview, (_, { id }) => id],
  (galleryList, reviewsList, isReviewer, review, folderId) => {
    if (isReviewer) return Boolean(review && review.ReviewStatus === ContentReviewStatus.Active)

    const folderPages = galleryList.filter((page) => page.folderId === folderId)

    // Folder can't be deleted if at least one folder page is added for review
    const activeReviews = reviewsList.filter((r) => r.ReviewStatus !== ContentReviewStatus.FeedbackSent)
    return !activeReviews.some((item) => item.PagesIds.some((pageId) => folderPages.some((fp) => fp.id === pageId)))
  }
)

export const getCanDeleteGalleryPage = createSelector(
  [
    getSelectedFolderGalleryListLength,
    getIsCurrentUserReviewer,
    getCurrentUserReview,
    getGalleryItemById,
    getGalleryNotSelectedItemsCount,
    getDifferentPagesCount,
    getSelectedFolderId,
    getIsItemAddedToActiveReview,
    getIsCurrentUserPresenter,
    getIsCallStarted,
    getHasDeleteRenameAllGalleryContentPermission,
    getHasDeleteRenameOwnGalleryContentPermission,
    getIsItemAddedToSelection,
  ],
  (
    galleryListLength,
    isReviewer,
    review,
    page,
    itemsCount,
    countDifferentPages,
    selectedFolderId,
    isAddedToReview,
    isPresenter,
    isCallStarted,
    hasDeleteRenameAllPermission,
    hasDeleteRenameOwnPermission,
    isAddedToSelection
  ) => {
    let canDelete = false

    if (isReviewer) {
      const isActiveReview = Boolean(review && review.ReviewStatus === ContentReviewStatus.Active)

      if (isActiveReview) {
        const { UploadedPagesIds } = review

        canDelete = UploadedPagesIds.includes(page.id)
      }
    } else {
      const isPresenterInCall = isCallStarted && isPresenter

      canDelete =
        page.canDelete &&
        (selectedFolderId || (itemsCount > 1 && galleryListLength > 1 && countDifferentPages > 1)) &&
        (hasDeleteRenameAllPermission || (!isCallStarted && hasDeleteRenameOwnPermission) || isPresenterInCall) &&
        !isAddedToReview

      canDelete =
        canDelete ||
        (isAddedToSelection &&
          (!isPdfPage(page.contentType) || (isPdfPage(page.contentType) && page.pdfPageNumber === 1)))
    }

    return canDelete
  }
)

export const getCanRenameGalleryPage = createSelector(
  [
    getGalleryItemById,
    getIsCurrentUserPresenter,
    getIsCallStarted,
    getHasDeleteRenameAllGalleryContentPermission,
    getHasDeleteRenameOwnGalleryContentPermission,
  ],
  (page, isPresenter, isCallStarted, hasDeleteRenameAllPermission, hasDeleteRenameOwnPermission) => {
    const isPresenterInCall = isCallStarted && isPresenter

    const canRename =
      hasDeleteRenameAllPermission ||
      (!isCallStarted && page.canRename && hasDeleteRenameOwnPermission) ||
      (isPresenterInCall && page.canRename)

    return canRename
  }
)

export const getGalleryItem = createSelector(
  [
    getGalleryItemById,
    getIsItemAddedToSelection,
    getIsItemAddedToSpatialModel,
    getIsItemAddedToCustomVideo,
    getIsItemAddedTo3dModel,
    getIsItemAddedToBentleyModel,
    getIsItemAddedToBentleyAnalyticsReport,
    getIsItemAddedToSharingSessionsPagesList,
    getIsItemAddedToReviewingSessionsPagesList,
    getIsItemAddedToStructureModel,
    getIsItemAddedToTowerSideProfile,
    getIsItemAddedTo2dLineDrawing,
    getCanDeleteGalleryPage,
    getCanRenameGalleryPage,
    getIsItemAddedToContentsMerging,
    getIsAddedToClassificationAnnotations,
    getIsItemAddedToSplatModel,
  ],
  (
    item,
    isAddedToSelection,
    isAddedToSpatialModel,
    isAddedToCustomVideo,
    isAddedTo3dModel,
    isAddedToBentleyModel,
    isAddedToBentleyAnalyticsReport,
    isAddedToSessionSharingPagesList,
    isAddedToSessionReviewingPagesList,
    isAddedToStructureModel,
    isAddedToTowerSideProfile,
    isAddedTo2dLineDrawing,
    canDelete,
    canRename,
    isAddedToContentsMerging,
    isAddedToClassificationAnnotations,
    isAddedToSplatModel
  ) => {
    return {
      ...item,
      canDelete,
      canRename,
      isAddedToSelection,
      isAddedToSpatialModel,
      isAddedToCustomVideo,
      isAddedTo3dModel,
      isAddedToBentleyModel,
      isAddedToBentleyAnalyticsReport,
      isAddedToSessionSharingPagesList,
      isAddedToSessionReviewingPagesList,
      isAddedToStructureModel,
      isAddedToTowerSideProfile,
      isAddedTo2dLineDrawing,
      isAddedToContentsMerging,
      isAddedToClassificationAnnotations,
      isAddedToSplatModel,
    }
  }
)

export const getCanAddGalleryContent = createSelector(
  [getCurrentUserReview, getIsCurrentUserPresenter, getIsCallStarted, getHasAddGalleryContentPermission],
  (review, isPresenter, isCallStarted, hasPermission) => {
    const isAvailable = isCallStarted ? isPresenter : true
    const isAddButtonHidden = Boolean(review && review.ReviewStatus !== ContentReviewStatus.Active)

    return isAvailable && !isAddButtonHidden && hasPermission
  }
)

export const getCanSwitchPages = createSelector(
  [getIsCurrentUserPresenter, getIsCallStarted],
  (isPresenter, isCallStarted) => {
    return isPresenter || !isCallStarted
  }
)

export const getIsSpatialViewOpened = createSelector([getSelectedGalleryItem], (selectedItem) => {
  return Boolean(selectedItem && selectedItem.contentType === ContentType.SpatialModel)
})

export const getIsWordDocumentOpened = createSelector([getSelectedGalleryItem], (selectedItem) => {
  return Boolean(
    selectedItem &&
      (selectedItem.contentType === ContentType.DocDocumentPage ||
        selectedItem.contentType === ContentType.PptDocumentPage ||
        selectedItem.contentType === ContentType.ExcelDocumentPage)
  )
})

export const getIsFilePageOpened = createSelector([getSelectedGalleryItem], (selectedItem) => {
  return Boolean(selectedItem && selectedItem.contentType === ContentType.File)
})

export const getIsSessionReviewingEnabled = createSelector(
  [getAppConfigState, getHasShareContentPermission],
  (appConfigState, hasPermission) => {
    const { isSessionReviewingEnabled } = appConfigState
    return isSessionReviewingEnabled && hasPermission
  }
)

export const getIsSessionSharingEnabled = createSelector(
  [getAppConfigState, getHasShareContentPermission],
  (appConfigState, hasPermission) => {
    const { isSessionSharingEnabled } = appConfigState
    return isSessionSharingEnabled && hasPermission
  }
)

export const getIsPageVisibleForUser = createSelector(
  [getIsCurrentUserReviewer, getCurrentUserReview, getReviewersUploadedPagesIds, (_, { pageId }) => pageId],
  (isReviewer, currentUserReview, uploadedByReviewersPages, pageId) => {
    if (isReviewer) {
      const { PagesIds, UploadedPagesIds } = currentUserReview

      return PagesIds.includes(pageId) || UploadedPagesIds.includes(pageId)
    }
    return !uploadedByReviewersPages.includes(pageId)
  }
)

export const getPreviousPageId = createSelector(
  [getPagesByFolder, getSelectedGalleryItemId],
  (galleryList, selectedGalleryItemId) => {
    const selectedGalleryItemIndex = galleryList.findIndex((item) => item.id === selectedGalleryItemId)
    const previousPage = galleryList[selectedGalleryItemIndex - 1]

    return previousPage?.id
  }
)

export const getFollowingPageId = createSelector(
  [getPagesByFolder, getSelectedGalleryItemId],
  (galleryList, selectedGalleryItemId) => {
    const selectedGalleryItemIndex = galleryList.findIndex((item) => item.id === selectedGalleryItemId)
    const followingPage = galleryList[selectedGalleryItemIndex + 1]

    return followingPage?.id
  }
)

export const getAddButtonMenuItems = createSelector(
  [getIsCurrentUserReviewer, getAppConfigState, getPointCloudModelStatus],
  (isReviewer, appConfigState, pointCloudModelStatus) => {
    const {
      show360View,
      show3dModel,
      showBentleyModel,
      showStructureModel,
      showTowerSideProfile,
      show2dLineDrawing,
      isAutodeskViewerEnabled,
      showBentleyAnalyticsReport,
      isClassificationAnnotationsCreationEnabled,
      isPointCloudModelsEnabled,
      is3DModelCreationLimitEnabled,
      isUtilityInfrastructureAnalysisEnabled,
      showMenuAI,
      showSplatModel,
    } = appConfigState

    const defaultItem = {
      menuItemType: null,
      title: null,
      disabled: false,
      isVisible: true,
    }

    let visibleItems = [
      addMenuItems.PDF,
      addMenuItems.WordDocument,
      addMenuItems.Images,
      addMenuItems.VideoFile,
      addMenuItems.VideoFromUrl,
    ]

    if (!isReviewer) {
      const additionalItems = [
        addMenuItems.WebPageUsingUrl,
        addMenuItems.Add360View,
        addMenuItems.Add3dModel,
        addMenuItems.AddBentleyModel,
        addMenuItems.AddSplatModel,
        addMenuItems.PointCloudModel,
        addMenuItems.AutodeskFile,
        addMenuItems.StructureModel,
        addMenuItems.BentleyAnalyticsReport,
        addMenuItems.TowerSideProfile,
        addMenuItems.TwoDLineDrawing,
        addMenuItems.DrawingPage,
        addMenuItems.ClassificationAnnotations,
        addMenuItems.UtilityInfrastructureAnalysis,
      ]

      visibleItems = [...visibleItems, ...additionalItems]
    }

    const is3dModelAvailable =
      pointCloudModelStatus === PointCloudModelStatus.NotCreated || !is3DModelCreationLimitEnabled
    const result = visibleItems.map((menuItem) => {
      switch (menuItem) {
        case addMenuItems.Add3dModel:
          return {
            ...defaultItem,
            menuItemType: menuItem,
            title: is3dModelAvailable ? null : '3D model already created',
            disabled: !is3dModelAvailable,
            isVisible: show3dModel && is3dModelAvailable,
          }
        case addMenuItems.AddBentleyModel:
          return {
            ...defaultItem,
            menuItemType: menuItem,
            isVisible: showBentleyModel,
          }

        case addMenuItems.StructureModel:
          return {
            ...defaultItem,
            menuItemType: menuItem,
            isVisible: showStructureModel,
          }
        case addMenuItems.TowerSideProfile:
          return {
            ...defaultItem,
            menuItemType: menuItem,
            isVisible: showTowerSideProfile,
          }
        case addMenuItems.TwoDLineDrawing:
          return {
            ...defaultItem,
            menuItemType: menuItem,
            isVisible: show2dLineDrawing,
          }
        case addMenuItems.Add360View:
          return {
            ...defaultItem,
            menuItemType: menuItem,
            isVisible: show360View,
          }
        case addMenuItems.AddSplatModel:
          return {
            ...defaultItem,
            menuItemType: menuItem,
            isVisible: showSplatModel,
          }
        case addMenuItems.PointCloudModel:
          return {
            ...defaultItem,
            menuItemType: menuItem,
            isVisible: isPointCloudModelsEnabled,
          }
        case addMenuItems.AutodeskFile:
          return {
            ...defaultItem,
            menuItemType: menuItem,
            isVisible: isAutodeskViewerEnabled,
          }
        case addMenuItems.BentleyAnalyticsReport:
          return {
            ...defaultItem,
            menuItemType: menuItem,
            isVisible: showBentleyAnalyticsReport,
          }
        case addMenuItems.ClassificationAnnotations:
          return {
            ...defaultItem,
            menuItemType: menuItem,
            isVisible: showMenuAI && isClassificationAnnotationsCreationEnabled,
          }
        case addMenuItems.UtilityInfrastructureAnalysis:
          return {
            ...defaultItem,
            menuItemType: menuItem,
            isVisible: isUtilityInfrastructureAnalysisEnabled,
          }
        default:
          return {
            ...defaultItem,
            menuItemType: menuItem,
          }
      }
    })

    return result
  }
)
